import request from '@/utils/request';
export var fileTransferHeaders = {};
export var defaultCategoryData = {
  id: 0,
  enabled: true,
  name: '',
  icon: '',
  image: '',
  description: '',
  priority: 0,
  featured: true,
  parent: null,
  bgcolor: null,
  amountStartingFrom: null
};
export var getCategories = function getCategories(params) {
  return request({
    url: '/categories',
    method: 'get',
    params: params
  });
};
export var getCategoriesTree = function getCategoriesTree() {
  return request({
    url: '/categories/list/tree',
    method: 'get'
  });
};
export var getCategoryById = function getCategoryById(id) {
  return request({
    url: "/categories/".concat(id),
    method: 'get'
  });
};
export var updateCategory = function updateCategory(id, data) {
  return request({
    url: "/categories/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteCategory = function deleteCategory(id) {
  return request({
    url: "/categories/".concat(id),
    method: 'delete'
  });
};
export var createCategory = function createCategory(data) {
  return request({
    url: '/categories',
    method: 'post',
    data: data
  });
};